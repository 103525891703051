<template>
  <div class="library-track" v-bind:class="{'orange darken-3':isPlaying}">
    <img class="album-art" v-bind:src="album.images[1].url" />
    <div class="controls">
      <div class="items">
        <div class="item" v-if="spotifyTrack.preview_url">
          <a
            href="#"
            v-on:click="listenToPreview($event)"
            class="waves-effect waves-light btn-floating blue"
            title="Listen to preview">
            <i class="material-icons">headset</i>
          </a>
        </div>

        <div class="item">
          <a
            href="#"
            v-on:click="addToPlaylist($event)"
            class="waves-effect waves-light btn-floating orange"
            title="Add to playlist">
            <i class="material-icons">playlist_add</i>
          </a>
        </div>

        <div class="item">
          <a
            href="#"
            v-if="track==null"
            v-on:click="addToLibrary($event)"
            class="waves-effect waves-light btn-floating green"
            title="Add to library">
            <i class="material-icons">library_add</i>
          </a>
        </div>

        <div class="item">
          <a
            href="#"
            v-if="track!=null"
            v-on:click="removeFromLibrary($event)"
            class="waves-effect waves-light btn-floating red"
            title="Remove from library">
            <i class="material-icons">remove</i>
          </a>
        </div>
      </div>
    </div>
    <div class="details">
      <div class="trackName truncate">{{trackName}}</div>
      <div v-if="spotifyTrack.name != album.name" class="albumName truncate">{{album.name}}</div>
      <div class="artists">
        <div
          v-for="item in spotifyTrack.artists"
          v-bind:key="item.id"
          class="artistLink"
        >{{item.name}}</div>
      </div>
      
      <!--
      <div class="controls">
        <a href="#" v-on:click="addToPlaylist($event)" class="waves-effect waves-light btn-floating btn-small orange" title="Add to playlist"><i class=" material-icons">add_to_queue</i></a>
        <a href="#" v-if="track==null" v-on:click="addToLibrary($event)" class="waves-effect waves-light btn-floating btn-small green" title="Add to library"><i class=" material-icons">add</i></a>
        <a href="#" v-if="track!=null" v-on:click="removeFromLibrary($event)" class="waves-effect waves-light btn-floating btn-small red" title="Remove from library"><i class=" material-icons">remove</i></a>
      </div>
      -->
    </div>
    <div class="likes" v-if="track">
        <div class="like up"><div class="btn btn-floating blue tiny"><i class="material-icons">thumb_up</i></div>{{track.likes}}</div>
        <div class="like down"><div class="btn btn-floating red darken-3 tiny"><i class="material-icons">thumb_down</i></div>{{track.dislikes}}</div>
      </div>
  </div>
</template>

<script>
import player from "@/Hubs/Player";
import spotibox from "@/Hubs/SpotiBox";
import AudioPlayer from "@/systems/AudioPlayer"

export default {
  name: "LibraryItem",
  props: ["track", "spotifyTrack"],
  mounted: function() {
    var elems = document.querySelectorAll(".fixed-action-btn");
    var options = { direction: "right" };
    window.M.FloatingActionButton.init(elems, options);
  },
  methods: {
    addToPlaylist: function(ev) {
      ev.preventDefault();
      if (this.track != undefined) {
        player.addToPlaylist(this.track.id);
      } else {
        player.addToPlaylist(this.spotifyTrack);
      }
    },
    addToLibrary: function(ev) {
      ev.preventDefault();
      spotibox.addToLibrary(this.spotifyTrack);
    },
    removeFromLibrary: function(ev) {
      ev.preventDefault();
      spotibox.removeFromLibrary(this.track.id);
    },
    listenToPreview: function(ev) {
      ev.preventDefault();
      AudioPlayer().play(this.spotifyTrack.preview_url)
    }
  },
  computed: {
    currentTrack() {
      return this.$store.getters.currentTrack;
    },
    hasTrackData() {
      return this.$store.getters.hasCurrentTrack;
    },
    isPlaying() {
        if (this.$store.getters.spotiBoxState.currentSpotiBoxTrack) {
        var sbtrack = this.$store.getters.spotiBoxState.currentSpotiBoxTrack.spotifyTrack;
        var track = this.$store.getters.spotiBoxState.currentSpotiBoxTrack
         .track;
        if (
         (sbtrack.name == this.$props.spotifyTrack.name &&
            sbtrack.artists[0].name ==
              this.$props.spotifyTrack.artists[0].name) ||
          (this.$props.track != null && track.id == this.$props.track.id)
      )
          return true;
      }
      return false;
    },
    
    album() {
      if (this.spotifyTrack.images != undefined) {
        return this.spotifyTrack;
      } else {
        return this.spotifyTrack.album;
      }
    },
    trackName() {
      if (this.name != undefined) {
        return this.name;
      } else {
        return this.spotifyTrack.name;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.btn-floating.tiny{
  width:18px;
  height:18px;
  line-height: 17px;
  .material-icons{
    font-size: .6em;
    line-height:18px;
  }
}

.library-track {
  position:relative;
  background: rgba(0, 0, 0, 0.5);
  float: left;
  width: 200px;
  height: 300px;
  padding: 5px;
  margin: 5px;
  transition: background-color 0.3s;

  color: #fff;

  .album-art {
    width: 100%;
  }

  &.playing {
    background: rgba(255, 0, 0, 0.5);
  }

  .details {
    padding: 5px;
    position: relative;
    top: -190px;
  }

  .albumName,
  .trackName,
  .artists {
    width: 100%;
    height: 20px;
    overflow: hidden;
    font-size: 12px;
    color: #ccc;
  }

  .trackName {
    font-size: 14px;
    color: #fff;
  }

  .artistLink {
    display: inline;
    font-size: 10px;
  }

  .artists .artistLink:after {
    content: ", ";
  }

  .artists .artistLink:last-child:after {
    content: "";
  }

  .likes {
    position: absolute;
    bottom: 10px;
    width:100%;
    height:20px;

    .like {
      display: inline-block;
      margin-right:20px;
      font-size:0.8em;
      .btn-floating{
        margin-right:5px;
        margin-left:5px;
      }

    }
  }

  .controls {
    position: relative;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.3s;
    opacity: 0;
    top: -196px;
    background: rgba(0, 0, 0, 0.8);
    height: 190px;
    width: 190px;
    left: 0px;
    text-align: center;
    padding-top: 20px;

    .items {
      transition: opacity 0.4s, margin-top 0.4s;
      //transition-delay: 0.2s;
      margin-top: 20px;
      opacity: 0;
    }

    .item {
      margin-bottom: 15px;
    }

  }

  &:hover {
    background: rgba(0, 0, 0, 1);

    .controls {
      opacity: 1;
      .items {
        margin-top: 0px;
        opacity: 1;
      }
    }
  }
}
</style>