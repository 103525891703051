
<template>
  <div class="container-results">

    <form @submit.prevent="handleSubmit" class="col s12 form-search">
      <div class="row">
         <div class="input-field col s3">
          <select v-model="search.filter" >
            
            <option value="track:">Song</option>
            <option value="artist:">Artist</option>
            <option value="album:">Album</option>
            <option value>Any</option>
          </select>
        </div>
        <div class="input-field col s8">
          <input id="search" type="text"  v-model="search.query" />
          <label for="search" v-bind:class="{'active':query != ''}" >Search term</label>
        </div>
       <div class="input-field col s1">
          <button type="submit" class="waves-effect waves-light btn-floating btn-large"><i class="material-icons left">search</i></button>
        </div>
      </div>
      
    </form>

    <div v-if="hasResults">
      <h2 class="white-text">Showing results for {{query}}</h2>
      <div v-if="searchResults.tracks.items.length > 0" class="results-block">
        <h4 class="white-text">Tracks</h4>
        <LibraryItem
          v-for="item in searchResults.tracks.items"
          v-bind:key="item.id"
          v-bind:track="null"
          v-bind:spotifyTrack="item"
        ></LibraryItem>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/systems/Search";
import LibraryItem from "@/components/LibraryItem";

export default {
  name: "Search",
  components: {
    LibraryItem
  },
  data() {
    return {
      search: {
        query: this.$store.getters.searchQuery,
        type: "track",
        filter: this.$store.getters.searchFilter
      },
      results: {}
    };
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    window.M.FormSelect.init(elems);
  },
  computed: {
    searchResults() {
      return this.$store.getters.searchResults;
    },
    hasResults() {
      if (
        this.$store.getters.searchResults.tracks != undefined ||
        this.$store.getters.searchResults.albums != undefined ||
        this.$store.getters.searchResults.playlists != undefined
      ) {
        return true;
      }
      return false;
    },
    query(){
      return this.$store.getters.searchQuery
    },
    filter(){
      return this.$store.getters.searchFilter
    }
  },
  methods: {
    handleSubmit() {
        // Send data to the server or update your stores and such.

        if(this.search.query !== undefined && this.search.query != ""){
        var query = this.search.filter + this.search.query;

        this.$store.commit("StoreSearchQuery",this.search.query );
        this.$store.commit("StoreSearchFilter",this.search.filter );

        Search.search(query, this.search.type).then(results => {
            results.tracks.items = Search.sortResults(results.tracks.items);
          this.$store.commit("StoreSearchResults",results)

        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container-results {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.form-search{
  background:rgba(255,255,255,.9);
  padding:20px;
}

.results-block {
  clear: both;
}
</style>