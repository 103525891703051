import Vue from "vue"
import SpotiBoxHub from '@/Hubs/SpotiBox'

const Search = new Vue({

  data() {
    return {
      SearchRequest: {},
    }
  },
  methods: {
    dummy() {

    },
    comparePopulrity(a, b) {
      // Use toUpperCase() to ignore character casing
      const trackA = a.popularity;
      const trackB = b.popularity;
    
      let comparison = 0;
      if (trackA > trackB) {
        comparison = 1;
      } else if (trackA < trackB) {
        comparison = -1;
      }
      return comparison * -1;
    },
    search(query, type) {
      var res, rej;
      var promise = new Promise((resolve, reject) => {
        res = resolve;
        rej = reject;
      });

      promise.resolve = res;
      promise.reject = rej;

      var queryObject = {
        q: query,
        t: type,
        requestTime: new Date().getTime(),
        completeTime: null,
        fulfilled: false,
        promise: promise
      }

      this.SearchRequest = queryObject;
      SpotiBoxHub.search(query, type);

      return promise;

    },
    resolveSearchRequest(query, type, results) {
      this.SearchRequest.promise.resolve(JSON.parse(results));
    },
    sortResults(data){
      return data.sort(this.comparePopulrity);
    },
    created: function () {}
  }
});


export default Search