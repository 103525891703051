import Vue from "vue"
import Search from "@/systems/Search"
import store from '@/systems/Store'
import {
  spotifyAPI
} from "../systems/Spotify";


const signalR = require("@microsoft/signalr");
const connection = new signalR.HubConnectionBuilder().withUrl("/hubs/SpotiBox").build();

const SpotiBoxHub = new Vue({
  Id: "",
  methods: {
    dummy() {

    },
    register() {
      connection.invoke("Register");
    },
    search(query, type) {
      connection.invoke("SpotifySearch", query, type, SpotiBoxHub.Id);
    },
    addToLibrary(track){
      connection.invoke("AddToLibrary", JSON.stringify(track));
    },
    removeFromLibrary(id){
      connection.invoke("RemoveFromLibrary", id);
    }
  },
  created: function () {
    connection.start().then(function () {
      SpotiBoxHub.register();
    }).catch(function (err) {
      return console.error(err.toString());
    });
  }
})

connection.on("StoreId", function (id) {
  SpotiBoxHub.Id = id;
})


connection.on("SEARCH_RESULTS", function (results, query, type) {
  //got the Search results back
  //console.log("RESULTS " + results + " - " + query);
  Search.resolveSearchRequest(query, type, results);
})

connection.on("ErrorMessage", function(error){
  console.error(error);
});

connection.on("LibraryTrackRemoved",function(){
  store.dispatch("getLibrary");
});

connection.on("LibraryTrackAdded",function(){
  store.dispatch("getLibrary");
});

connection.on("SEARCH_REQUEST", function (query, type, id) {
  //do the lookup if master
  if (store.getters.isMasterPlayer) {
    spotifyAPI().search(query, type).then(response => {
      connection.invoke("SearchResult", query, type, id, JSON.stringify(response));
    }).catch(e => {
      connection.invoke("SearchResult", query, type, id, JSON.stringify({
        error: e
      }));
    });
  }
})

export default SpotiBoxHub