const soundFile = document.createElement("audio");
const src = document.createElement("source");

const AudioPlayer =  function(){

  
  var path = "";
  //Create the audio tag

    soundFile.preload = "auto";

    //Load the sound file (using a source element for expandability)
    
    soundFile.appendChild(src);

    //Load the audio tag
    //It auto plays as a fallback
    soundFile.load();
    soundFile.volume = 0.000000;
    soundFile.play();

    //Plays the sound
  function play(url) {
    console.log(url);
    path = url;
    if(src.src !== path){
      src.src = path;
      //Set the current time for the audio file to the beginning
      soundFile.currentTime = 0.01;
      soundFile.volume = 1;
      //Due to a bug in Firefox, the audio needs to be played after a delay
      
      setTimeout(function(){soundFile.play();},1);
    }else{
      stop();
    }
  }

  function stop() {
    src.src = "";
    //Set the current time for the audio file to the beginning
    soundFile.currentTime = 0.01;
    soundFile.pause()
  }

  return{
    play,
    stop
  }
}

export default AudioPlayer